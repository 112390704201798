import React, { memo, useState } from 'react';
import breakPoints, { fonts } from 'helpers/Styles';

const VideoWithoutPlay = ({
  videoUrl,
  videoSource,
  videoTitle,
  videoDescription,
}) => (
  <>
    <div className="video">
      <video loop={true} muted autoPlay={true} playsInline={true}>
        <source src={videoSource} type="video/mp4" />
      </video>
      <div className="overlay">
        {videoTitle !== null ? (
          <span className="title">{videoTitle}</span>
        ) : null}
        {videoDescription !== null ? (
          <span className="description">{videoDescription}</span>
        ) : null}
      </div>
    </div>
    <style jsx>
      {`
          video {
            width: 100%;
            height: 600px;
            object-fit: cover;
          }
          .video {
            position: relative;
          }
          .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // height: 408px;
            align-items: center;
            top: 0;
            font-family: ${fonts.bigNoodleTitling};
          }
          .title {
            color: #e4e4e4;
            font-size: 49px;
          }
          .description{
            width: 70%;
            color: #E4E4E4;
            font-family: "Roboto-Medium";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin-top: 30px;

          }
          @media (max-width: ${breakPoints.medium}) {
            video{
              height: unset;
            }
            .overlay {
              margin-top: 16px;
              position: unset;
              top: unset;
            }
            .title{
              font-size: 26px;
            }
            .description{
              width: 100%;
            }
          }
          {/* @media (max-width: ${breakPoints.small}) {
            video{
              height: 400px;
            } 
            } */}
          }
        `}
    </style>
    <style jsx global>
      {`
        .mobile-youtube {
          display: none;
          text-align: center;
        }
        @media (max-width: ${breakPoints.medium}) {
          .mobile-youtube {
            display: block;
          }
          .desktop-youtube {
            display: none !important;
          }
        }
      `}
    </style>
  </>
);

export default memo(VideoWithoutPlay);
