import { memo } from 'react';
import breakPoints from 'helpers/Styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import VideoWithoutPlay from 'components/VideoWithoutPlay';

const YoutubeVideo = ({ block }) => {
  const {
    title1,
    description,
    button_link,
    button_text,
    video_url,
    video_title,
    video_link,
  } = block.primary;

  const title = title1 ? title1 : null;
  const videoTitle = video_title ? video_title : null;
  const descriptions = description ? description : null;
  const videoDescription =
    block.primary && block.primary.video_description
      ? block.primary.video_description
      : null;

  return (
    <>
      <div className="series_video_slice">
        <div className="video_block">
          {video_link?.url && (
            <VideoWithoutPlay
              videoUrl={video_url}
              videoHeight="600px"
              videoSource={video_link?.url}
              videoTitle={videoTitle}
              videoDescription={videoDescription}
            />
          )}
        </div>

        {title && (
          <div className="find_your_setup">
            <div className="title">
              <h1>{title}</h1>
              <p>{descriptions}</p>
            </div>

            {button_text && (
              <div className="button_container">
                <button type="button" className="two_column_button">
                  {button_text}
                </button>
                <div className="arrow_right">
                  <img draggable="false" src="/icons/icon_arrow.svg" alt="icon_arrow" />
                </div>
              </div>
            )}
          </div>
        )}

        <style jsx>
          {`
            .series_video_slice {
              padding: 70px 0;
            }
            .video_block {
              width: 65%;
              height: 500px;
              margin: 0 auto;
              margin-bottom: 180px;
            }
            .button_container {
              display: flex;
              width: 200px;
              margin: 0 auto;
            }
            .find_your_setup{
              displayL flex;
              justify-content: center;
            }
            .title{
              width: 80%;
              margin: 0 auto;
            }
             .two_column_button {
              height: 48px;
              width: 128px;
              color: white;
              background: #891d1a;
              font-family: 'Roboto-Medium';
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 24px;
              border-radius: 3px 0 0 3px;
              border: none;
              outline: 0;
              text-transform: uppercase;
            }
            .arrow_right {
              width: 48px;
              height: 48px;
              background: #242424;
              display: flex;
              justify-content: center;
              cursor: pointer;
            }
            .arrow_right img {
              width: 25px;
            }
            .title h1 {
              color: #e4e4e4;
              font-family: 'big_noodle_titling';
              font-size: 31px;
              letter-spacing: 0;
              line-height: 40px;
              text-align: center;
            }
            .title p {
              color: #e4e4e4;
              font-family: 'Roboto-Medium';
              font-size: 16px;
              letter-spacing: 0;
              line-height: 24px;
              text-align: center;
            }
            @media (max-width: ${breakPoints.medium}) {
              .series_video_slice{
                padding: 64px 0;
              }
              .video_block {
                width: 90%;
                height: unset;
                margin-top: 30px;
                margin-bottom: 0px;
            
              }
              .find_your_setup{
                padding-top: 30px;
                padding-bottom: 30px;
              }
            @media (max-width: ${breakPoints.small}) {
              .video {
                height: unset;
              }
              .series_video_slice {
                padding: 0px 0;
              }
              .video_block {
                width: 90%;
                height: unset;
              }
              .find_your_setup{
                padding-top: 30px;
                padding-bottom: 30px;
              }
              
          `}
        </style>
      </div>
    </>
  );
};

export default memo(YoutubeVideo);
