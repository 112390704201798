import css from 'styled-jsx/css';

export default css`
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .header-container {
    width: 907px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-family: 'Roboto-Medium';
    font-weight: bold;
    text-align: center;
  }
  .header {
    font-family: 'Roboto-Medium';
    font-weight: bold;
  }
  .description {
    text-align: center;
    font-size: 15px;
  }
  .product-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
  }
  .product-card {
    width: 31%;
    display: flex;
    flex-direction: column;
  }
  .product-img {
    height: 280px;
    cursor: pointer;
  }
  .product-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px 0 25px;
  }
  .product-header {
    font-family: 'Roboto-Medium';
    margin: 0;
    text-transform: uppercase;
  }
  .product-name {
    margin: 0;
    font-weight: bold;
    font-family: 'Roboto-Medium';
  }
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    border: none;
    background-color: #75b800;
    color: white;
    text-transform: none;
    padding: 10px 15px 10px 15px;
    border-radius: 2px;
    font-weight: bold;
  }
  .copyright-container {
    margin-top: 50px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .copyright-sub-container {
    width: 907px;
  }
  .logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 25px;
  }
  .aftershock-logo {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .geforce-logo {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .logo-img {
    height: 40px;
  }
  .copyright-text {
    font-size: 11px;
    font-family: 'Calibri';
    color: #7b7b7b;
    text-align: center;
  }
  @media (max-width: 940px) and (min-width: 601px) {
    .header-container {
      width: 80%;
    }
    .product-img {
      height: auto;
    }
    .copyright-sub-container {
      width: 85%;
    }
    .logo-img {
      height: 30px;
    }
  }
  @media (max-width: 600px) {
    .main-container {
      margin-top: -40px;
    }
    .header-container {
      width: 80%;
      padding: 0;
    }
    .title {
      font-size: 22px;
    }
    .header {
      font-size: 18px;
    }
    .description {
      font-size: 14px;
    }
    .product-container {
      flex-direction: column;
    }
    .product-card {
      width: 100%;
      margin-bottom: 50px;
    }
    .product-img {
      height: 280px;
    }
    .copyright-container {
      margin-top: 30px;
    }
    .copyright-sub-container {
      width: 85%;
    }
  }
`;