import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';

const HolidayOffers = ({ block }) => {
  if (!block) return '';

  const { primary, items } = block;

  const redirectTo = (url) => {
    window.location = url;
  };
  return (
    <>
      <div className="main-container">
        <div className="header-container">
          <div>
            <h1 className="title">{primary.title1}</h1>
          </div>
          <div>
            <h3 className="header">{primary.header}</h3>
          </div>
          <div>
            <p className="description">{primary.description}</p>
          </div>

          <div className="product-container">
            {items.map((data, index) => {
              return (
                <div className="product-card" key={index}>
                  <div className="product-img">
                    <img draggable="false"
                      src={data.product_image.url}
                      width={'100%'}
                      height={'100%'}
                      onClick={() => redirectTo(data.button_link || '#')}
                      alt='product_image'
                    />
                  </div>
                  <div className="product-details">
                    <h4 className="product-header">{data.header}</h4>
                    <p className="product-name">{data.product_name}</p>
                  </div>
                  <div className="btn-container">
                    <button
                      className="btn"
                      onClick={() => redirectTo(data.button_link || '#')}
                    >
                      {data.button_text != undefined
                        ? data.button_text
                        : 'Buy Now'}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {primary.geforce_logo.url && (
          <div className="copyright-container">
            <div className="copyright-sub-container">
              <div className="logo-container">
                <div className="aftershock-logo">
                  <div className="logo-img">
                    <img draggable="false"
                      src="/icons/header_logo.svg"
                      width={'100%'}
                      height={'100%'}
                      alt='header_logo'
                    />
                  </div>
                </div>
                <div className="geforce-logo">
                  <div className="logo-img">
                    <img draggable="false"
                      src={primary.geforce_logo.url}
                      width={'100%'}
                      height={'100%'}
                      alt='geforce_logo'
                    />
                  </div>
                </div>
              </div>

              <div>
                <p className="copyright-text">
                  {primary.copyright_description}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <style jsx>{styles}</style>
      <style jsx>
        {`
          .header-container {
            padding-bottom: 50px;
            border-bottom: ${primary.geforce_logo.url
              ? '2px solid #2a2a2a'
              : ''};
          }
        `}
      </style>
    </>
  );
};

HolidayOffers.propTypes = {
  block: PropTypes.shape({
    primary: PropTypes.shape({
      title1: PropTypes.string,
      header: PropTypes.string,
      description: PropTypes.string,
      geforce_logo: PropTypes.object,
      copyright_description: PropTypes.string,
    }),
    items: PropTypes.array,
  }),
};

HolidayOffers.defaultProps = {
  block: null,
};

export default HolidayOffers;
